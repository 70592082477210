<template>
  <div id="studentsNotes" class="text-sm" >
    <div class="flex flex-wrap justify-between mb-6">
      <a-button class="mr-2" @click="$router.go(-1)">
        Regresar
      </a-button>
<!--      <a-dropdown width="w-80">-->
<!--        <template #menu>-->
<!--          &lt;!&ndash;          <a-dropdown-item @click="handleEvaluated">&ndash;&gt;-->
<!--          <a-dropdown-item @click="printDiv">-->
<!--            imprimir-->
<!--          </a-dropdown-item>-->
<!--        </template>-->
<!--      </a-dropdown>-->
    </div>
<!--    <div class="flex flex-1/2 justify-between mb-3 text-lg mx-2">-->
<!--      <div>-->
<!--        <p class="inline-block mr-2">Materia:</p>-->
<!--        <p class="inline-block font-bold">{{ lessonValues.course_name }}</p>-->
<!--      </div>-->
<!--      <div>-->
<!--        <p class="inline-block mr-2">Sección: </p>-->
<!--        <p class="inline-block font-bold">{{ lessonValues.section_code }}</p>-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="loading" class="w-16 h-16"/>
    <div v-else>
      <a-table
        :columns="columns"
        :source="[ ...classes ]"
        class="rounded rounded-md overflow-hidden"
      >
<!--        <template #toolbar(search)>-->
<!--          <div class="flex items-center p-2">-->
<!--            <h4 class="inline-block text-2xl">-->
<!--              Nombre de la clase-->
<!--            </h4>-->
<!--          </div>-->
<!--        </template>-->
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from '@/filters/moment'

export default {
  name: 'courseNote',
  data () {
    return {
      loading: false,
      classes: [],
      columns: [
        { title: 'Clases', key: 'subject'},
        { title: 'Fecha', key: 'date' },
        { title: 'Corte', key: 'period_cut' },
        { title: 'Evaluado', key: 'evaluated', mutate: ({ key }) => key ? 'Si' : 'No' },
        { title: 'Fecha de evaluación', key: 'evaluated_at', mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : '' },
        { title: 'Porcentaje evaluado', mutate: ({ key }) => key.evaluated ? key.evaluation_weighting_prc + '%' : 'N/A' },
        { title: 'Porcentaje obtenido', mutate: ({ key }) => key.evaluated ? key.pivot.note_weighting_prc + '%' : '' },
        { title: 'Nota', mutate: ({ key }) => key.evaluated && key.pivot.note !== null ? key.pivot.note : '' },
      ]
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    }),
    moment: () => moment
    // mappedClasses () {
    //   // this.classes = this.classes.students[0].first_cut.map($0 => $0)
    //   let lessons = this.classes.map($0 => ({ ...$0 }))
    //   // this.classes = {...this.classes.students[0].first_cut,...this.classes.students[0].second_cut}
    //   // console.log(this.classes)
    //   const lessons = []
    //   firstCut?.forEach((value) => lessons.push(value))
    //   secondCut?.forEach((value) => lessons.push(value))
    //   return lessons
    // },
  },
  mounted () {
    this.loading = true
    this.$repository.sections
      .courseSectionStudent(this.$route.params.section_id, this.$route.params.course_id, this.user.student.id, { limit: 0, order_by: 'date' })
      .then(({ data: response }) => {
        this.classes = response
      })
      .finally(() => this.loading = false)
  }
}
</script>

<style scoped>

</style>
